import './App.css';
const appStoreURL = "https://www.apple.com/app-store/"

function LandingPageContent(props) {
    const { headingText, descriptionText, image, screenshot } = props;

    return (
        <div className="landing-page-content">
            <div className='MainImageAndText'>
                <div class="text-container">
                    <p className = "HeadingText" >
                        {headingText}
                    </p>

                    <p className = "SupportingText" >
                       {descriptionText}
                    </p>

                    <a href= {appStoreURL}>
                     <img className="appStoreImage" src={image} alt="app store" />
                    </a>
                </div>
               
                <img src={screenshot} className = "MainImage" alt="dashboard1" />
            </div>
        </div>
        
    );
  }
  
  export default LandingPageContent;
// Theme.js
// AppTheme.js

const AppTheme = {
    primary: "#3A604C",
    complementary: "#AFAC5D",
    secondary: "#9CB2CE",
    textOnLight: "#000000",
    textOnDark: "#FFFFFF",
    secondaryTextOnLight: "#808080",
    secondaryTextOnDark: "#B3B3B3",
    backgroundLight: "#FDFAF1",
    backgroundDark: "#000000",
    secondaryBackgroundLight: "#F0EDE4",
    secondaryBackgroundDark: "#404040",
    shadowOnLight: "#DADADA",
    shadowOnDark: "#202020",
    success: "#008000",
    error: "#FF0000",
    warning: "#FFFF00",
    accentGreyLight: "#E6E1D9",
    accentGreyDark: "#525252"
  };
  
  export default AppTheme;  
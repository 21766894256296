import './App.css';

function Support() {
    return (
        <div className="support-content">
            <div className='MainImageAndText'>
                <div className="text-container">

                <p class = "HeadingText" >
                       Support
                 </p>
                 <p className = "SupportingText" >
                       If you're experiencing any issues or have any ideas for the app you would like
                       to submit, please email us @ itsgrowtimeapp@gmail.com! We will get back to you within 24 hours.
                </p>
                    <a className = "SupportingTextLink"  href="mailto:itsgrowtimeapp@gmail.com">Email Us</a>
                </div>
            </div>
        </div>
        
    );
  }
  
  export default Support;
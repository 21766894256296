// import './App.css';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LandingPageContent from './LandingPageContent';
import appStoreDownloadImg from './iOSAppStoreDownloadWhite.svg';
import dashboard from './DashboardImage.PNG';
import prospects from './Prospects.PNG';
import ProspectProfile from './ProspectsProfile.jpg';
import completedProspects from './CompletedProspectsFiltered.jpg';
import teamBoards from './TeamBoards.jpg';
import subBoards from './subboards.jpg';
import threads from './threads.jpg';
import messages from './Messages.jpg';
import scheduledCalls from './ScheduledCalls.jpg';
import reminders from './Reminders.jpg';
import userContactCard from './UserContactCard.jpg';


class LandingPageCarousel extends React.Component {
   render() {
     const settings = {
       slidesToShow: 1,
       slidesToScroll: 1,
       arrows: true,
       infinite: false,
       variableWidth: true,
       autoplay: true, // Enable autoplay
       autoplaySpeed: 5000, // Autoplay interval in milliseconds (7 seconds),
       dots: true
     };
    
     return (
       <div className="slider-container">
         <Slider {...settings}>
           <LandingPageContent 
               headingText="Manage your business"
               descriptionText="Track your progress and stay organized with a clear overview of your performance and category-wise progress."
               image = {appStoreDownloadImg}
               screenshot = {dashboard}
           />
           <LandingPageContent 
               headingText= "Organize your prospects"
               descriptionText= "Efficiently manage and access detailed profiles of all your prospects in one convenient grid view."
               image = {appStoreDownloadImg}
               screenshot = {prospects}
           />
            <LandingPageContent 
               headingText= "Track prospect life cycle"
               descriptionText= "Monitor and manage every stage of your prospect's journey with detailed checklists and notes for streamlined tracking."
               image = {appStoreDownloadImg}
               screenshot = {ProspectProfile}
           />
           <LandingPageContent 
               headingText= "View prospect history"
               descriptionText= "Easily filter and review the history of completed prospects, including their status, category as a prospect, and their city of origin."
               image = {appStoreDownloadImg}
               screenshot = {completedProspects}
           />
           <LandingPageContent 
               headingText= "Join teams, share boards"
               descriptionText= "Collaborate seamlessly by accessing and sharing team-wide resources through organized boards."
               image = {appStoreDownloadImg}
               screenshot = {teamBoards}
           />
            <LandingPageContent 
               headingText= "Utilize team resources"
               descriptionText= "Access and leverage a collection of shared resources, including language samples, images, checklists, and external links."
               image = {appStoreDownloadImg}
               screenshot = {subBoards}
           />
           <LandingPageContent 
               headingText= "Join team or group threads"
               descriptionText= "Engage in collaborative discussions through team group chats, user group chats, and one-on-one conversations."
               image = {appStoreDownloadImg}
               screenshot = {threads}
           />
           <LandingPageContent 
               headingText= "Chat with other users"
               descriptionText= "Communicate directly with other users through text, images, and links in message threads."
               image = {appStoreDownloadImg}
               screenshot = {messages}
           />
           <LandingPageContent 
               headingText= "Track scheduled calls"
               descriptionText= "Keep organized and prepared by tracking all your scheduled calls with prospects, including details and relevant notes."
               image = {appStoreDownloadImg}
               screenshot = {scheduledCalls}
           />
           <LandingPageContent 
               headingText= "View your reminders"
               descriptionText= "Organize your schedule and stay productive with a comprehensive view of your reminders, tailored to your tasks and prospects."
               image = {appStoreDownloadImg}
               screenshot = {reminders}
           />
           <LandingPageContent 
               headingText= "Check in with your team"
               descriptionText= "Facilitate effective team collaboration by easily accessing and connecting with team members through their contact information."
               image = {appStoreDownloadImg}
               screenshot = {userContactCard}
           />
         </Slider>
       </div>
     );
   }
 }
  export default LandingPageCarousel;

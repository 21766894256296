import './App.css';
import AppTheme from './theme';
import LandingPageCarousel from './LandingPageCarousel';
import NavBar from './NavBar';
import Support from './Support';
import Privacy  from './Privacy'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const appStyle = {
    backgroundColor: AppTheme.backgroundLight, // Replace with your desired background color
    minHeight: '100vh', // Ensure app takes at least the height of the viewport
    display: 'flex',
    flexDirection: 'column',
};
  return (
    <Router>
      <div className="App" style={appStyle}>
      
        <header className="App-header" style={{ backgroundColor: AppTheme.backgroundLight }}>
          <NavBar/>
        </header>
        <div className = "main-content">
          <Routes>
            <Route exact path="/" element= {<LandingPageCarousel/>} />
            <Route path="/support" element= {<Support/>} />
            <Route path="/privacy" element= {<Privacy/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

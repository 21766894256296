import './App.css';
import logo from './ItsGrowTimeText.png';
import FontFamilies from './font';
import AppTheme from './theme';
import { Link } from 'react-router-dom';

function NavBar() {
   return (
   <div className='NavBarContainer'>
       <Link to="/">
           <img src={logo} className="App-logo" alt="logo" />
       </Link>
       <ul class = 'NavBarLinks'>
           <li>
               <Link to="/support">
                   <p style={{ fontFamily: FontFamilies.regular, color: AppTheme.primary }} >
                       Support
                   </p>
               </Link>
           </li>

           <li>
               <Link to="/privacy">
                   <p style={{ fontFamily: FontFamilies.regular, color: AppTheme.primary }} >
                       Privacy
                   </p>
               </Link>
           </li>
       </ul>
    </div>
   );
 }
  export default NavBar;
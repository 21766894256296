import './App.css';

function Privacy() {
    return (
        <div className="support-content">
            <div className='MainImageAndText'>
                <div className="text-container">
                    <p className="HeadingText">Privacy Policy</p>
                    <p className="SupportingText">
                        Welcome to ItsGrowTime. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, and share information through our App.
                        <br /><br />
                        2. Information We Collect
                        <br /><br />
                        We collect various types of information in connection with the services we provide, including:
                        <br /><br />
                        a. Personal Information
                        <br /><br />
                        Account Information: When you create an account, we collect your name, email address, and contact information. Profile Information: Information you provide when setting up your profile, such as a profile picture, goals, and more.
                        <br /><br />
                        b. Prospect Information
                        <br /><br />
                        Information about the prospects you manage, including contact details and life cycle stage.
                        <br /><br />
                        c. Calendar and Reminder Information
                        <br /><br />
                        Access to your Apple Calendar and Apple Reminders to schedule calls and set reminders.
                        <br /><br />
                        d. Team and Collaboration Information
                        <br /><br />
                        Information related to teams you join, content shared, and communications within team chats and direct messages.
                        <br /><br />
                        e. Usage Information
                        <br /><br />
                        Information about how you use the App, including features you interact with and the content you view.
                        <br /><br />
                        3. How We Use Your Information
                        <br /><br />
                        We use the information we collect to: Provide and improve the App and its features. Facilitate communication and collaboration between team members. Schedule and manage calls and reminders. Personalize your experience and provide relevant content. Respond to your inquiries and provide customer support. Monitor and analyze usage and trends to improve the App. Comply with legal obligations and enforce our policies.
                        <br /><br />
                        4. Data Security
                        <br /><br />
                        We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
                        <br /><br />
                        5. Your Choices and Rights
                        <br /><br />
                        You may access, update, or delete your personal information through the App's settings. You may also manage notifications and other preferences. If you wish to close your account or have any concerns about your data, please contact us.
                        <br /><br />
                        6. Changes to This Privacy Policy
                        <br /><br />
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                        <br /><br />
                        7. Contact Us
                        <br /><br />
                        If you have any questions or concerns about this Privacy Policy or our data practices, please email us at itsgrowtimeapp@gmail.com .
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
